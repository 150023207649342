.navbar {
	min-height: $navbar-min-height;
	border-color: #e5e5e5;

	.navbar-header {
		.navbar-brand {
			img {
				max-height: 50px;
				max-width: 97px;
				margin-top: 10px;

				&.logo-grupo {
					margin-top: 0;
				}
			}
		}
	}

	.navbar-nav {
		li {
			a {
				padding: 0 30px;
				line-height: $navbar-min-height;
			}
		}
	}
}