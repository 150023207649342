.modal-content {
	border-radius: 0px;

	.modal-header {
		background: #cecece;
	}

	.modal-footer {
		border-top: 0px;
	}
}