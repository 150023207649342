// Homepage
html { height:100%; }
body { height: 100%; margin-top: $navbar-min-height; background: #ffffff; font-family: "Lato", sans-serif }
h2 { font-family: 'PT Serif', serif; font-size: 50px; margin: 30px 0; }
h3 { font-family: 'PT Serif', serif; font-size: 25px; }

// 
.introduction {
	padding: 70px 0px;

	h2 {
		margin: 0 0 40px 0;
	}

	p {
		max-width: 600px;
		font-size: 18px;
		font-weight: 300;	
	}
}

.info-text {
	width: 70%;
	margin: 0 auto 30px auto;
}

// Home
.home {
	background: url('/img/background.jpg') no-repeat;
	background-size: cover;
	margin: 0;

	.logo-group {
		margin-bottom: 40px; 

		img {
			max-width: 60%;
			margin: 0 auto;
		}
	}

	.logos {
		margin: 0 auto;

		.logo {
			background-color: rgba(255, 255, 255, .5);
			padding: 40px;
			margin-bottom: 20px;
			margin-right: 20px;

			&:hover {
				background-color: rgba(255, 255, 255, .7);
				outline: 2px solid black;
			}

		}
	}

	.container-logos {
		margin: 0 auto;
	}
}

// Taxis
.taxis {
	.introduction {
		// background: linear-gradient(135deg, #52dd8e 0%,#008800 100%);
		background: url('img/taxisBanner.jpg') no-repeat center center;
		background-size: cover; 
		color: #fff;
		text-shadow: 1px 1px 15px #000000;
	}
}

.automoveis {
	.introduction {
		// background: linear-gradient(135deg, #3e3e3e 0%,#1a1a1a 100%);
		background: url('img/automoveisBanner.jpeg') no-repeat center center;
		background-size: cover; 
		color: #fff;
		text-shadow: 1px 1px 15px #000000;
	}

	.cars {
		margin-bottom: 25px;
		.car-card {
			img {
				max-height: 110px;
			}
			a {
				color: #000;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.carousel {
		margin-bottom: 20px;
	}

	.information {
		margin-bottom: 25px;
		h4 {
			font-size: 17px;
			font-weight: 600;
		}
	}
}

.cga {
	.introduction {
		background: url('img/cgaBanner.jpeg') no-repeat center center;
		background-size: cover; 
		color: #fff;
		text-shadow: 1px 1px 15px #000000;
	}
}

.funeraria {

	&.show {
		h4 {
			font-size: 17px;
			font-weight: 600;
		}

		.social-buttons {
			margin-top: $grid-gutter-width;
		}

		.comments {
			border-top: 1px solid #e5e5e5;
			margin-top: 65px;
		}
	}

	.introduction {
		// background: linear-gradient(135deg, #e073ff 0%,#5e3077 100%);
		background: url('img/bg_fn_2.jpg') no-repeat center center;
		background-size: cover; 
		color: #fff;
		text-shadow: 1px 1px 15px #000000;
		// margin-bottom: 60px;
		min-height: 353px;

		&.information {
			.person-info {
				margin-left: 315px;
    			margin-top: 40px;
				
				h2 {
					margin-top: $grid-gutter-width;
				}

				h3 {
					font-size: 15px !important;
				}

				@media screen and (max-width: 750px) {
					margin: 0;
					display: block;
					text-align: center;
				}
			}

			img {
				margin-bottom: 30px;
				max-width: 210px;
				max-height: 210px;
				position: absolute;
				top: 175px;

				@media screen and (max-width: 750px) {
					position: relative;
				    top: 0;
				    margin: 0 auto;
				}
			}
		}

	}

	.details {
		padding-top: 25px;
	}

	.people {
		.person-card {
			line-height: 20px;
			min-height: 410px;

			h4 {
				font-size: 16px;
			}

			p {
				margin: 0; 
			}

			@media screen and (max-width: 750px) {
				text-align: center;
				margin-bottom: 25px;

				img {
					max-width: 150px;
					margin: 0 auto;
				}

				&:first-child {
					margin-top: 25px;
				}
			}
		}
	}

	.search {
		a {
			display: block;
			margin-top: 6px;
		}
	}

	a {
		// color: $text-color;

		// &:hover, &:active, &:visited, &:link {
		// 	text-decoration: none;
		// }
	}

	section {
		border-bottom: 1px solid #e5e5e5;
    	padding-bottom: 65px;
    	// margin-top: 65px;

    	h2 {
    		margin: 30px 0;
    	}

    	&.people {
    		a {
				color: $text-color;

				&:hover, &:active, &:visited, &:link {
					text-decoration: none;
				}
			}
    	}

    	&.faqs {
    		.panel-group {
    			.panel-default {
    				background: transparent;
    				border: 0;

    				.panel-body {
    					border-top-color: #e5e5e5;
    				}
    			}
    		}
    	}
	}

}

.contactos {
	.introduction {
		background: linear-gradient(135deg, #3e3e3e 0%,#1a1a1a 100%);
		color: #fff;
	}

	.contactos, .mapa{
		margin-top: 17px;
	}

	#map {
		width: 100%;
		height: 470px;
		margin: 37px 0;
	}

}

// Footer
.footer {
	min-height: 350px;
	padding: 30px 0;
	background: #eeeeee;

	ul {
		padding: 0;
		list-style: none;

		li {
			a {
				color: $text-color;

				&:hover, &:visited, &:active {
					color: $text-color;
					text-decoration: none;
				}
			}
		}

		&.social {
			li {
				float: left;
				margin-right: 10px;
			}
		}
	}
}

.center-vertically {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}